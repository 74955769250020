"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Created by Bart Decorte
 * Date: 25/10/2017
 * Time: 14:39
 */

var Page = function () {
    function Page() {
        _classCallCheck(this, Page);

        Response.create({
            prop: "width",
            prefix: "min-width- r src",
            breakpoints: [752, 0],
            lazy: true
        });

        console.log('init');

        this.gaDevelopment();
        this.cacheSelectors();

        this.navigation();
        this.responsiveVideos();
        this.faqList();
        this.tableCollapse();
        this.languageToggle();
        this.mobileScrollTo();
        this.stagesSwitch();
        this.muteVideo();
        this.socialSharer();
        this.masonry();
        this.blogText();

        this.hashListeners();
    }

    _createClass(Page, [{
        key: "blogText",
        value: function blogText() {
            if ($('.preview-text').length) {
                var text = $('.preview-text').text();
                var newText = '';
                var wordsLimit = 80;
                var wordsArray = text.split(' ');

                if (wordsLimit < wordsArray.length) {
                    newText = wordsArray.splice(0, wordsLimit).join(' ') + '...';
                } else {
                    newText = text;
                }
                $('.preview-text p').remove();
                $('.preview-text iframe').remove();
                $('.preview-text img').remove();
                $('.preview-text').append('<p>' + newText + '</p>');
            } else {}
        }
    }, {
        key: "socialSharer",
        value: function socialSharer() {

            this.params = {
                selector: undefined,
                facebookSelector: undefined,
                twitterSelector: undefined,
                linkedinSelector: undefined,
                popupHeight: undefined,
                popupWidth: undefined
            };

            this.defaults = {
                selector: '.js-social-sharer',
                facebookSelector: '.js-social-sharer--facebook',
                twitterSelector: '.js-social-sharer--twitter',
                linkedinSelector: '.js-social-sharer--linkedin',
                popupHeight: 300,
                popupWidth: 500
            };

            this.construct = function (params) {
                var scope = this;
                if ((typeof params === "undefined" ? "undefined" : _typeof(params)) === (typeof undefined === "undefined" ? "undefined" : _typeof(undefined))) params = {};

                var key;
                for (key in scope.params) {
                    if (scope.params.hasOwnProperty(key)) {
                        if (params.hasOwnProperty(key)) {
                            scope.params[key] = params[key];
                        } else {
                            scope.params[key] = scope.defaults[key];
                        }
                    }
                }

                scope.initPinterest();
                scope.bindListeners();
            };

            this.bindListeners = function () {
                var scope = this;
                var $body = $('body');
                $body.on('click', scope.params.selector, function (ev) {
                    return scope.listeners.shareClick(scope, ev);
                });
            };

            this.listeners = this.listeners || {};
            this.listeners.shareClick = function (scope, ev) {
                var $elt = $(ev.target).is(scope.params.selector) ? $(ev.target) : $(ev.target).parents(scope.params.selector).first();

                if ($elt.is(scope.params.facebookSelector)) {
                    scope.shareFacebook();
                } else if ($elt.is(scope.params.twitterSelector)) {
                    scope.shareTwitter();
                } else if ($elt.is(scope.params.linkedinSelector)) {
                    scope.shareLinkedin();
                } else {
                    return true;
                }

                ev.preventDefault();
                return false;
            };

            this.getPopupFeatures = function () {
                var scope = this;
                return 'location=yes,width=' + scope.params.popupWidth + ',height=' + scope.params.popupHeight;
            };

            this.shareFacebook = function (url) {
                var scope = this;
                if ((typeof url === "undefined" ? "undefined" : _typeof(url)) === (typeof undefined === "undefined" ? "undefined" : _typeof(undefined))) url = window.location.href;

                window.open('https://www.facebook.com/sharer.php?u=' + url, '_blank', scope.getPopupFeatures());
            };

            this.shareTwitter = function (url, msg, hashtags) {
                var scope = this;
                if ((typeof url === "undefined" ? "undefined" : _typeof(url)) === (typeof undefined === "undefined" ? "undefined" : _typeof(undefined))) url = window.location.href;
                if ((typeof msg === "undefined" ? "undefined" : _typeof(msg)) === (typeof undefined === "undefined" ? "undefined" : _typeof(undefined))) msg = '';
                if ((typeof hashtags === "undefined" ? "undefined" : _typeof(hashtags)) === (typeof undefined === "undefined" ? "undefined" : _typeof(undefined)) || !Util.isArray(hashtags)) hashtags = [];

                window.open('http://twitter.com/share?url=' + url + '&text=' + msg + '&hashtags=' + hashtags.join(','), '_blank', scope.getPopupFeatures());
            };

            this.shareLinkedin = function (url) {
                var scope = this;
                if ((typeof url === "undefined" ? "undefined" : _typeof(url)) === (typeof undefined === "undefined" ? "undefined" : _typeof(undefined))) url = window.location.href;

                window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + url, '_blank', scope.getPopupFeatures());
            };

            this.initPinterest = function () {
                var scope = this;
                var $btns = $('.js-social-sharer--pinterest');
                var i, $btn;
                for (i = 0; i < $btns.length; i++) {
                    $btn = $($btns[i]);
                    $btn.attr('data-pin-media', $btn.attr('href')).attr('href', 'https://www.pinterest.com/pin/create/button').attr('data-pin-do', 'buttonPin').attr('data-pin-custom', 'true');
                }
                if ($btns.length) $('body').append($('<script type="text/javascript" async defer src="//assets.pinterest.com/js/pinit.js"></script>'));
                return scope;
            };

            this.construct(arguments[0]);
        }
    }, {
        key: "masonry",
        value: function masonry() {
            $(document).ready(function () {
                setTimeout(function () {
                    if ($('#social-grid-holder').length > 0) {
                        var $grid = $('#social-grid-holder').masonry({
                            itemSelector: '.facebook-template',
                            percentPosition: true,
                            columnWidth: '.grid-sizer'
                        });
                        $grid.imagesLoaded().progress(function () {
                            $grid.masonry();
                        });
                    }
                    if ($('.cabins__grid').length > 0) {
                        var $cabinGrid = $('.cabins__grid').masonry({
                            itemSelector: '.cabins__grid-item',
                            percentPosition: true,
                            gutter: '.gutter-sizer',
                            columnWidth: '.grid-sizer'
                        });
                        $cabinGrid.imagesLoaded().progress(function () {
                            $cabinGrid.masonry();
                        });
                    }
                    if ($('blogpost-masonry .blogpost__grid').length > 0) {
                        var $cabinGrid = $('.blogpost__grid').masonry({
                            itemSelector: '.cabins__grid-item',
                            percentPosition: true,
                            gutter: '.gutter-sizer-three',
                            columnWidth: '.grid-sizer-three'
                        });
                        $cabinGrid.imagesLoaded().progress(function () {
                            $cabinGrid.masonry();
                        });
                    }
                }, 1000);
            });
        }
    }, {
        key: "stagesSwitch",
        value: function stagesSwitch() {
            $('document').ready(function () {
                $('.artists .filter-sailing').each(function (key, value2) {
                    if ($(value2).hasClass('active')) {
                        var $stageBox = $('.stages__listing .grid__item');
                        $.each($stageBox, function (key, value) {
                            if ($(value).attr('data-day') == $(value2).attr('href').replace('#day-', '')) {
                                $(value).css('display', 'block');
                            } else {
                                $(value).css('display', 'none');
                            }
                        });
                    }
                });
            });

            $('.artists .filter-sailing').click(function (e) {
                $('.artists .filter-sailing').removeClass('active');
                $(e.currentTarget).addClass('active');
                var $stageBox = $('.stages__listing .grid__item');
                $.each($stageBox, function (key, value) {
                    if ($(value).attr('data-day') == $(e.currentTarget).attr('href').replace('#day-', '')) {
                        $(value).css('display', 'block');
                    } else {
                        $(value).css('display', 'none');
                    }
                });
            });
        }
    }, {
        key: "fancybox",
        value: function fancybox() {
            $('[data-fancybox]').fancybox({
                // Options will go here
                // arrows: false,
            });
        }
    }, {
        key: "languageToggle",
        value: function languageToggle() {
            $('.lang').click(function (e) {
                // $('ul').css("display","block");
                $('.lang ul').slideToggle();
            });
            this.mobileScrollTo();
        }
    }, {
        key: "muteVideo",
        value: function muteVideo() {

            window.YT.ready(function () {
                var lineup = new YT.Player('lineup', {
                    height: '315',
                    width: '560',
                    videoId: '1xE4ueCkI9k',
                    playerVars: { autoplay: 1, mute: 1 },
                    events: {}
                });
                var teaser = new YT.Player('player', {
                    height: '315',
                    width: '560',
                    videoId: 'UMyeGvMJGao',
                    playerVars: { autoplay: 1, mute: 1 },
                    events: {}
                });
                var firstAftermovie = new YT.Player('history-2018', {
                    height: '315',
                    width: '560',
                    videoId: 'UMyeGvMJGao',
                    playerVars: { autoplay: 0, mute: 0 },
                    events: {}
                });
                var secondAftermovie = new YT.Player('history-2017', {
                    height: '315',
                    width: '560',
                    videoId: 'kAanc9dwlMA',
                    playerVars: { autoplay: 0, mute: 0 },
                    events: {}
                });
                $('#cta-mute').click(function () {
                    if ($('#cta-mute').hasClass('muted')) {
                        $(this).removeClass('muted');
                        $(this).addClass('unmuted');
                        teaser.unMute();
                    } else {
                        $(this).addClass('muted');
                        $(this).removeClass('unmuted');
                        teaser.mute();
                    }
                });
                $('#cta-mute--lineup').click(function () {
                    if ($('#cta-mute--lineup').hasClass('muted')) {
                        $(this).removeClass('muted');
                        $(this).addClass('unmuted');
                        lineup.unMute();
                    } else {
                        $(this).addClass('muted');
                        $(this).removeClass('unmuted');
                        lineup.mute();
                    }
                });
                $('#cta-mute--firstAftermovie').click(function () {
                    if ($('#cta-mute--firstAftermovie').hasClass('muted')) {
                        $(this).removeClass('muted');
                        $(this).addClass('unmuted');
                        firstAftermovie.unMute();
                    } else {
                        $(this).addClass('muted');
                        $(this).removeClass('unmuted');
                        firstAftermovie.mute();
                    }
                });
                $('#cta-mute--secondAftermovie').click(function () {
                    if ($('#cta-mute--secondAftermovie').hasClass('muted')) {
                        $(this).removeClass('muted');
                        $(this).addClass('unmuted');
                        secondAftermovie.unMute();
                    } else {
                        $(this).addClass('muted');
                        $(this).removeClass('unmuted');
                        secondAftermovie.mute();
                    }
                });
            });
        }
    }, {
        key: "mobileScrollTo",
        value: function mobileScrollTo() {
            $('.pricing a.filter-sailing').click(function (e) {
                console.log('clicked');
                var scrollplace = $('#rooms-pricing').offset().top + $('.extra__info').height();
                console.log(scrollplace);
                if ($(window).innerWidth() < 980) {
                    console.log('je kan scrollen');
                    $("html, body").animate({ scrollTop: scrollplace }, "slow");
                }
            });
        }
    }, {
        key: "faqList",
        value: function faqList() {

            $('.faq-list .faq-list__title').click(function () {
                var $faqItem = $(this).parents('.faq-list__item');

                if ($faqItem.hasClass('active')) {
                    $faqItem.removeClass('active');
                    $faqItem.find('.faq-list__content').slideToggle();
                } else {
                    $('.faq-list .faq-list__item.active .faq-list__content').slideToggle();
                    $('.faq-list .faq-list__item').removeClass('active');
                    $faqItem.addClass('active');
                    $faqItem.find('.faq-list__content').slideToggle();
                }
            });
        }
    }, {
        key: "tableCollapse",
        value: function tableCollapse() {
            var table = $('.pricing__type-table');
            // if(table.length > 0){
            //   var pax2 = $('.pax2');
            //   var pax4 = $('.pax4');
            //   var pax6 = $('.pax6');
            //   var pax8 = $('.pax8');
            // }
            $('.back').click(function (e) {
                e.preventDefault();

                $('.' + $(this).parent().attr('class') + '').css('display', 'none');
                var whichpax = $(this).parent().attr('class').replace("pax", "");
                var newColumn = 'pax' + (parseInt(whichpax) - 1);

                $('.' + newColumn + '').css('display', 'table-cell');
            });
            $('.forward').click(function (e) {
                e.preventDefault();

                $('.' + $(this).parent().attr('class') + '').css('display', 'none');
                var whichpax = $(this).parent().attr('class').replace("pax", "");
                var newColumn = 'pax' + (parseInt(whichpax) + 1);

                console.log($(this).parent().parent().parent().find('.' + newColumn + ''));
                $('.' + newColumn + '').css('display', 'table-cell');
            });
        }
    }, {
        key: "cacheSelectors",
        value: function cacheSelectors() {
            this.cache = {
                $html: $('html'),
                $nav: $('.main-nav__wrap')
            };
        }
    }, {
        key: "navigation",
        value: function navigation() {
            var _this = this;

            $('.main-nav__trigger').click(function (e) {
                console.log('test');
                e.preventDefault();
                console.log($(_this).next('.main-nav__wrap'));
                $('.main-nav__wrap').slideToggle('fast');
            });

            Response.crossover('width', function () {
                if (Response.band(752)) {
                    console.log('check');
                    _this.cache.$nav.css('display', 'inline-block');
                } else {
                    console.log('check wrong');
                    _this.cache.$nav.css('display', 'none');
                }
            });
        }
    }, {
        key: "responsiveVideos",
        value: function responsiveVideos() {
            $('iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]').wrap('<div class="video-container"></div>');
        }

        // Ajax newsletter subscribe

    }, {
        key: "formAjax",
        value: function formAjax() {
            $('.form-ajax').submit(function (e) {
                var $form = $(this);
                $form.find('button,input[type="submit"]').attr("disabled", "disabled");

                $.post($form.attr('action'), $form.serializeArray(), function (data) {
                    if (data.errors === false) {
                        $form.html(data.result);
                    } else {
                        $form.find('.result').html(data.result);
                        $form.find('button, input[type="submit"]').removeAttr('disabled');
                    }
                });

                e.preventDefault();
                return false;
            });
        }
    }, {
        key: "formAjaxRecaptcha",


        // recaptcha formulier versturen met post
        value: function formAjaxRecaptcha() {
            $('.form-ajax-recaptcha').submit(function (e) {
                e.preventDefault();
                var $form = $(this);

                if ($form.hasClass('validate')) {
                    if ($form.validationEngine('validate') == false) {
                        return false;
                    }
                }

                if ($form.find('[name="g-recaptcha-response"]').length && $form.find('[name="g-recaptcha-response"]').val() != '') {
                    grecaptcha.reset();
                }

                grecaptcha.execute($form.find('.g-recaptcha').data('widgetid'));

                return false;
            });
        }
    }, {
        key: "recaptchaFormSend",


        // recaptcha submit handler
        value: function recaptchaFormSend(form) {
            $form = $(form);
            $form.find('input[type="submit"], button').attr('disabled', 'disabled');
            form_data = $form.serializeArray();

            console.log('recaptchaaa');

            // console.log('posted');
            // var email = $form.find('input[type="email"]').val();
            // console.log($('#mailchimp'));
            //
            // console.log($('#mailchimp').find('input[type="email"]'));
            // $('#mailchimp').find('input[type="email"]').val(email);

            $.post($form.attr('action'), form_data, function (data) {

                $('li').removeClass('error');
                var $result = $form.find('.result');

                if (data.errors === false) {
                    $form.html(data.result);

                    if (typeof ga === "function") {
                        ga('send', 'pageview', $form.attr('action'));
                    }

                    // $.ajax($('#mailchimp').attr('action'),{
                    //     'type' : 'POST',
                    //     success : function(data){
                    //
                    //     }
                    // })

                    //todo GTM trigger event
                } else {
                    $result.html(data.result);
                    console.log('error');
                    $form.find('input[type="submit"], button').removeAttr("disabled");
                    if (data.fields) {
                        $.each(data.fields, function (i, field) {
                            $('input[name="' + field + '"],textarea[name="' + field + '"]').addClass('error');
                        });
                    }
                }
            }).fail(function (response) {
                alert('Error: ' + response.responseText);
            });
        }
    }, {
        key: "submitRecaptchaFormNewsletter",
        value: function submitRecaptchaFormNewsletter(token) {
            recaptchaFormSend($('#form-newsletter'));
        }
    }, {
        key: "formValidation",


        // explicit recaptcha methode (zodat er meerdere op 1 pagina kunnen)


        value: function formValidation() {
            // if(esign.cache.isMobile) {
            //     $('.validate').validationEngine();
            // } else {
            //     $('.validate').validationEngine({scroll: false});
            // }
        }
    }, {
        key: "hashListeners",
        value: function hashListeners() {
            var _this2 = this;

            $(window).on('hashchange', function (ev) {
                return _this2.lsHashChange(ev);
            }).trigger('hashchange');
        }
    }, {
        key: "lsHashChange",
        value: function lsHashChange(ev) {
            var _this3 = this;

            ev.preventDefault();

            var hash = window.location.hash.substr(1);
            var hashParts = hash.split('_');

            hashParts.forEach(function (hashPart) {
                if (hashPart.startsWith('sailing-')) {
                    _this3.filter(hashPart);
                } else {
                    _this3.scrollToClassVisible(hashPart);
                }
            });

            return false;
        }
    }, {
        key: "filter",
        value: function filter(hash) {
            if (hash === undefined || hash == "") {
                var defaultElt = document.getElementsByClassName('filter')[0];
                hash = defaultElt.getAttribute('href').substr(1);
            }
            var filterClass = hash;

            console.log(hash);

            var $hide = $('.filter-sailing:not(.' + filterClass + '):not(.dis-none)');
            var $show = $('.' + filterClass + '.dis-none');

            var $activate = $('.filter-sailing[href="#' + filterClass + '"]:not(.active)');
            var $deactivate = $('.filter-sailing.active:not([href="#' + filterClass + '"])');

            $hide.toArray().forEach(function ($elt) {
                $($elt).addClass('dis-none');
            });
            $show.toArray().forEach(function ($elt) {
                $($elt).removeClass('dis-none');
            });

            $activate.toArray().forEach(function ($elt) {
                $($elt).addClass('active');
            });
            $deactivate.toArray().forEach(function ($elt) {
                $($elt).removeClass('active');
            });
        }
    }, {
        key: "scrollToClassVisible",
        value: function scrollToClassVisible(theClass) {
            $(window).on('load', function () {
                if (theClass.length && $('.' + theClass + ':not(.dis-none)').length) {
                    $('html, body').stop().animate({
                        scrollTop: $('.' + theClass + ':not(.dis-none)').first().offset().top
                    }, 1000);
                }
            });
        }

        // Log ga calls in development

    }, {
        key: "gaDevelopment",
        value: function gaDevelopment() {
            var _arguments = arguments;

            if (window.ga === undefined) {
                window.ga = function () {
                    var argumentsArray = [];
                    for (var key in _arguments) {
                        if (_arguments.hasOwnProperty(key)) {
                            argumentsArray.push(_arguments[key]);
                        }
                    }

                    var msg = '[GA DEV] Call with arguments [' + argumentsArray.join(',') + ']';
                    console.log('%c' + msg, 'background: #ff9800; color: #fff;');
                };
            }
        }
    }]);

    return Page;
}();