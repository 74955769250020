'use strict';

/**
 * Created by Bart Decorte
 * Date: 25/10/2017
 * Time: 15:39
 */

(function () {
    var bodyClass = $('body').attr('class').split(' ')[0];
    var className = bodyClass.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
    });
    className = className.charAt(0).toUpperCase() + className.slice(1);

    if (className !== 'History' && window[className] !== undefined) {
        new window[className]();
    } else {
        new Page();
    }
})();

function init() {
    console.log('check');
    formAjax();
    // formValidation();
    formAjaxRecaptcha();
    // onloadReCaptchaCallback();
}

// recaptcha submit handler per formulier
function submitRecaptchaFormContact(token) {
    console.log('test');
    recaptchaFormSend($('#form-contact'));
};

function onloadReCaptchaCallback() {
    $('.g-recaptcha').each(function (i, el) {
        var attributes = {
            'sitekey': $(el).data('sitekey'),
            'size': $(el).data('size'),
            'callback': $(el).data('callback')
        };

        var widgetid = grecaptcha.render(el, attributes);
        $(el).data('widgetid', widgetid);
    });
};

// Ajax newsletter subscribe
function formAjax() {
    $('.form-ajax').submit(function (e) {
        var $form = $(this);
        $form.find('button,input[type="submit"]').attr("disabled", "disabled");

        $.post($form.attr('action'), $form.serializeArray(), function (data) {
            if (data.errors === false) {
                $form.html(data.result);
            } else {
                $form.find('.result').html(data.result);
                $form.find('button, input[type="submit"]').removeAttr('disabled');
            }
        });

        e.preventDefault();
        return false;
    });
};

// recaptcha formulier versturen met post
function formAjaxRecaptcha() {
    $('.form-ajax-recaptcha').submit(function (e) {
        e.preventDefault();
        var $form = $(this);

        if ($form.hasClass('validate')) {
            if ($form.validationEngine('validate') == false) {
                return false;
            }
        }

        if ($form.find('[name="g-recaptcha-response"]').length && $form.find('[name="g-recaptcha-response"]').val() != '') {
            grecaptcha.reset();
        }

        grecaptcha.execute($form.find('.g-recaptcha').data('widgetid'));

        return false;
    });
};

// recaptcha submit handler
function recaptchaFormSend(form) {
    $form = $(form);
    $form.find('input[type="submit"], button').attr('disabled', 'disabled');
    form_data = $form.serializeArray();

    console.log('recaptchaaa');

    // console.log('posted');
    // var email = $form.find('input[type="email"]').val();
    // console.log($('#mailchimp'));
    //
    // console.log($('#mailchimp').find('input[type="email"]'));
    // $('#mailchimp').find('input[type="email"]').val(email);

    $.post($form.attr('action'), form_data, function (data) {

        $('li').removeClass('error');
        var $result = $form.find('.result');

        if (data.errors === false) {
            $form.html(data.result);

            if (typeof ga === "function") {
                ga('send', 'pageview', $form.attr('action'));
            }

            // $.ajax($('#mailchimp').attr('action'),{
            //     'type' : 'POST',
            //     success : function(data){
            //
            //     }
            // })

            //todo GTM trigger event
        } else {
            $result.html(data.result);
            console.log('error');
            $form.find('input[type="submit"], button').removeAttr("disabled");
            if (data.fields) {
                $.each(data.fields, function (i, field) {
                    $('input[name="' + field + '"],textarea[name="' + field + '"]').addClass('error');
                });
            }
        }
    }).fail(function (response) {
        alert('Error: ' + response.responseText);
    });
};

$(init);